import React from 'react';
import { Link } from 'gatsby';
import { css } from '@emotion/react';

import Layout from '../components/layout';
import Seo from '../components/seo';
import ImageLinkItem from '../components/ImageLinkItem';

import { theme } from '../styles/theme';

import listImage_adults from '../images/illustrations/illustration-adults@2x.png';
import listImage_infants from '../images/illustrations/illustration-infants@2x.png';
import listImage_neonates from '../images/illustrations/illustration-neonates@2x.png';
import listImage_lab from '../images/illustrations/illustration-lab@2x.png';

const IndexPage = () => {
  return (
    <Layout>
      <Seo title="Home" />
      <div css={theme.components.row}>
        <h1
          className="mb-gap"
          css={[theme.components.title, theme.components.titleLarge]}>
          Welcome to Blood Components
        </h1>

        <ul
          css={css`
            padding-top: 0.5rem;
            display: flex;
            justify-content: space-between;
            flex-wrap: wrap;
            margin-left: -1rem;
            margin-right: -1rem;

            & > li {
              padding-left: 1rem;
              padding-right: 1rem;
              margin-bottom: 2rem;

              @media (min-width: 820px) {
                width: 50%;
              }
            }
          `}>
          <li>
            <ImageLinkItem
              destination="/adults"
              imageName={listImage_adults}
              title="Adults"
              text="This guidance is based on the NBTC Indication Codes for Transfusion (January 2020)."
            />
          </li>
          <li>
            <ImageLinkItem
              destination="/infants"
              imageName={listImage_infants}
              title="Infants &amp; Children"
              text="This summary guidance should be used in conjunction with the 2016 BSH Guidelines (and 2020 Addendum)."
            />
          </li>
          <li>
            <ImageLinkItem
              destination="/neonates"
              imageName={listImage_neonates}
              title="Neonates"
              text="This summary guidance should be used in conjunction with the 2016 BSH Guidelines (and 2020 Addendum)."
            />
          </li>
          <li>
            <ImageLinkItem
              destination="/laboratory-best-practices"
              imageName={listImage_lab}
              title="Laboratory Best Practices"
              text="This summary guidance should be used in conjunction with the appropriate 2016 and 2012 BSH Guidelines and laboratory SOPs."
            />
          </li>
        </ul>

        <ul
          css={css`
            display: flex;
            justify-content: center;
            flex-wrap: wrap;
            margin-left: -1rem;
            margin-right: -1rem;

            & > li {
              padding-left: 1rem;
              padding-right: 1rem;
              margin-bottom: 2rem;
              width: auto;

              @media (min-width: 820px) {
                flex-basis: 330px;

                & > * {
                  width: 100%;
                  height: 100%;
                }
              }
            }
          `}>
          <li>
            <Link
              to="/about"
              css={[theme.components.button, theme.components.buttonGrey]}>
              About
            </Link>
          </li>
          <li>
            <Link
              to="/other-considerations"
              css={[theme.components.button, theme.components.buttonGrey]}>
              Other Considerations
            </Link>
          </li>
          <li>
            <Link
              to="/disclaimer"
              css={[theme.components.button, theme.components.buttonGrey]}>
              Disclaimer
            </Link>
          </li>
        </ul>
      </div>
    </Layout>
  );
};

export default IndexPage;
